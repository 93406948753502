<template>
    <div>
        <section class="section section-welcome-message">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <h1 class="welcome-message">Welcome <span v-if="currentUser">{{ currentUser.first_name }}</span></h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="section section-timeline" v-if="currentUser.hasOwnProperty('group') && currentUser.group.schedules.length > 0">
            <div class="container">
                <GroupSchedule v-if="currentUser.hasOwnProperty('group')" :group="currentUser.group"></GroupSchedule>
            </div>
        </section>

        <!-- <section class="section">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="row options-row">
                            <div class="col-xs-12">
                                <router-link :to="'/' + currentUser.group.id + '/schedule'">
                                    <button class="scheme-green">
                                        {{ currentUser.group.schedules.length > 0 ? "View the" : "Create a"}} payment schedule
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->


        <ParticipantList v-if="currentUser.hasOwnProperty('group')" :fromGroup="currentUser.group.id"></ParticipantList>
    </div>
</template>

<script>
import ParticipantList from './Users/ParticipantListGroupAdmin.vue';
import GroupSchedule from './Groups/GroupSchedule.vue';
import TransferFunds from './TransferFunds.vue';

export default {
    data(){
        return{
            currentUser: null,
            // paymentSchedules: 0
        }
    },
    created(){
        axios.get('/api/users/me').then((response) => {
            this.currentUser = response.data;
            // this.paymentSchedules = response.data.group.schedules.length;
        })
    },
	mounted() {
		// console.log('Component mounted.')
	},
    components: {
        ParticipantList,
        GroupSchedule,
        TransferFunds
    }
}
</script>
