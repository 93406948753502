<template>
<section class="section section-participant-list">

	<div class="container">
		<div class="row">
			<div class="col-xs-12 col-md-12">
				<div class="row options-row">
					<div class="col-xs-12"  v-bind:class="{'col-sm-6': this.$admin}">
						<template v-if="this.fromGroup == 27 && participants.length >= 30">
							<p class="text-center text-black">
								Participant limit reached.
							</p>
						</template>
						<template v-else>
							<router-link to="/participants/new">
								<button class="scheme-green">Create new participant</button>
							</router-link>
						</template>
					</div>

					<TransferFunds v-if="this.fromGroup == null && this.$admin" :participants="participants" @callback="fetchAll"></TransferFunds>
				</div>

                <div class="row" v-if="this.$admin">
                    <div class="col-xs-12">
                        <router-link to="/participants/archived">
                            <button >Browse archived users</button>
                        </router-link>
                    </div>
                </div>

			</div>
		</div>

        <div v-if="dataToLoad">
            <vue-good-table v-if="loaded" title="Participants" :columns="columns" :rows="rows" :paginate="true" :actions="actions" @edit="edit" @del="del" @reset="reset" @save="save" @view="view" @supplement="supplement" @activation="activation"/>
            <div v-else  style="margin:30px auto; text-align:center;">
                <div class="mnml-spinner dark" style="margin:0 auto">
                </div>
                <p class="text-small" style="margin-top:20px;">Loading Participants...</p>

            </div>
        </div>
        <div v-else>
            <br><br><br>
            <p class="text-small text-center">No participants in your group. Please begin by adding a participant.</p>
        </div>


	</div>

</section>
</template>

<script>
import Participant from './Participant.vue';
import TransferFunds from '../TransferFunds.vue';
import Search from '../Search.vue';
import NSearch from '../NSearch.vue';
import VueGoodTable from '../Table.vue';
// import Datatable from 'vuetiful'

export default {
	props: ['fromGroup'],
	data() {
		return {
			columns: [{
					label: 'First Name',
					field: 'first_name',
					filterable: true,
					editable: true,
				},
				{
					label: 'Last Name',
					field: 'last_name',
					filterable: true,
					editable: true,
				},
				{
					label: 'E-mail',
					field: 'email',
					filterable: true,
					editable: true,
				},
				{
					label: 'Package Cost',
					field: 'package_cost',
					type: 'number',
					html: false,
					filterable: true,
				},
				{
					label: 'Total Paid',
					field: 'total_paid',
					type: 'number',
					html: false,
					filterable: true,
				},
				{
					label: 'Remaining',
					field: 'total_remaining',
					type: 'number',
					html: false,
					filterable: true,
				},
                {
					label: 'Supplement',
					field: 'supplement',
					type: 'number',
					html: false,
					filterable: true,
				},
                {
                    label: 'Group',
                    field: 'group',
                    type: 'text',
                    html: false,
                    filterable: true,
                },

			],
			rows: [],
			actions: [{
				name: "Edit",
				icon: "ion-edit",
				callback: 'edit',
			}, {
				name: "Send activation",
				icon: "ion-ios-email",
				callback: 'activation',
			}, {
				name: "Payments",
				icon: "ion-eye",
				callback: 'view',
			}, {
				name: "Reset pass",
				icon: "ion-ios-reload",
				callback: 'reset',
			}, {
				name: "Remove supplement",
				icon: "ion-card",
				callback: 'supplement',
			}],
			creating: false,
			participant: {
				first_name: null,
				last_name: null,
				email: null,
				password: null,
				group: null,
			},
			participants: [],
			filteredGroupItems: [],
            loaded:false,
            dataToLoad: false,
		}
	},
	created() {
		this.fetchAll(this.fromGroup);
	},
	methods: {
        view(data,index){
            this.$router.push({ path: '/payments/' + data.row.id, params: { id: data.row.id }})
        },
		reset(data, index) {
			// console.log("RESET");
            // console.log(data);
            this.$Progress.start();
            axios.post('/api/users/' + data.row.id + '/reset', {email: data.row.email}).then((response) =>{
                // console.log(response);
                this.$Progress.finish();
                this.$toasted.success("Success! Reset password sent to '" + data.row.first_name + "'").goAway(6000);
            });
		},
		edit(row, index) {
			// console.log("editing");
			// console.log(row);
		},
        activation(data, index) {
            this.$Progress.start();
            axios.post('/api/send-activation/' + data.row.id).then((response) =>{
                // console.log(response);
                this.$Progress.finish();
                this.$toasted.success("Activation e-mail sent!").goAway(6000);
            })
            .catch((error) => {
                this.$Progress.fail();
                if (error.response) {
                    // Unprocessable entity is 422
                    if (error.response.status == 422) {
                        // validation failed
                        var obj = error.response.data;
                        for (var key in obj) {
                            // TODO PERHAPS MOVE THIS TO THE LARAVEL SIDE?
                            let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
                            // replace underscores
                            displayKey = displayKey.replace(/_/g, " ");

                            this.$toasted.error(displayKey + " : " + obj[key]);
                        }
                    }
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                }
            })
        },
        supplement(data, index) {
            this.$Progress.start();
            axios.delete('/api/supplement/' + data.row.id).then((response) =>{
                // console.log(response);
                this.$Progress.finish();
                this.$toasted.success("Success! Deleted user's supplement.").goAway(6000);
            })
            .catch((error) => {
                this.$Progress.fail();
                if (error.response) {
                    // Unprocessable entity is 422
                    if (error.response.status == 422) {
                        // validation failed
                        var obj = error.response.data;
                        for (var key in obj) {
                            // TODO PERHAPS MOVE THIS TO THE LARAVEL SIDE?
                            let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
                            // replace underscores
                            displayKey = displayKey.replace(/_/g, " ");

                            this.$toasted.error(displayKey + " : " + obj[key]);
                        }
                    }
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                }
            })
        },
		save(data, index) {
			// console.log("saving");
			// console.log(data);
			this.$Progress.start();
			axios.put('/api/users/' + data.row.id, {
				first_name: data.row.first_name,
				last_name: data.row.last_name,
				email: data.row.email,
				participant: true,
			})
			.then((response) => {
				// set to viewing mode after submission is saved
				this.viewing = true;
				// console.log(response);
				this.$Progress.finish();
				this.$toasted.success("Success! Participant: " + data.row.first_name + " updated.").goAway(6000);
			})
            .catch((error) => {
                this.$Progress.fail();
                if (error.response) {
                    // Unprocessable entity is 422
                    if (error.response.status == 422) {
                        // validation failed
                        var obj = error.response.data;
                        for (var key in obj) {
                            // TODO PERHAPS MOVE THIS TO THE LARAVEL SIDE?
                            let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
                            // replace underscores
                            displayKey = displayKey.replace(/_/g, " ");

                            this.$toasted.error(displayKey + " : " + obj[key]);
                        }
                    }
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                }
            })
		},
		del(data, index) {
			this.$Progress.start();
			axios.delete('/api/users/' + data.row.id)
			.then((response) => {
    			this.rows.splice(data.index, 1);
				this.$Progress.finish();
				this.$toasted.error("Success! Participant: '" + data.row.first_name + "' deleted.").goAway(6000);
			})
			.catch((error) => {
				this.$Progress.fail();
				if (error.response) {
					// Unprocessable entity is 422
					if (error.response.status == 422) {
						// validation failed
						var obj = error.response.data;
						for (var key in obj) {
							// TODO PERHAPS MOVE THIS TO THE LARAVEL SIDE?
							let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
							// replace underscores
							displayKey = displayKey.replace(/_/g, " ");

							this.$toasted.error(displayKey + " : " + obj[key]);
						}
					}
				} else {
					// Something happened in setting up the request that triggered an Error
					// console.log('Error', error.message);
				}
			})
		},
		fetchAll(limiter) {
            this.$Progress.start();


			if (limiter) {

                axios.get('/api/groups/' + limiter + '/users/count').then((response) => {
                    if(response.data.count > 0){
                        this.dataToLoad = true;
                    }
                });


				axios.get('/api/groups/' + limiter + '/users').then((response) => {
					this.participants = response.data;

					for (let i = 0; i < this.participants.length; i++) {
						let p = this.participants[i];
						let colour = "normal";
						let red = false;
						let yellow = false;

						// TODO WHAT DECIDES THE HIGHLIGHTING
						// decide whether they should highlight in red or yellow or nothing at all
						if(!p.supplement){
                            p.supplement = 0;
                        }
						if ((p.total_due_today - (p.total_paid - p.supplement)) >= 200) {
							colour = 'red';
							red = true;
						}
                        

						// for(let i = 0; i < 10000; i++){
                        // console.log(p.group);
                        this.rows.push({
                            id: p.id,
                            first_name: p.first_name,
                            last_name: p.last_name,
                            email: p.email,
                            package_cost: parseInt(p.total_required),
                            total_paid: parseInt(p.total_paid),
                            total_due_today: p.total_due_today,
                            total_overdue: (p.total_due_today - p.total_paid),
                            total_remaining: (p.total_required - p.total_paid),
                            supplement: (p.supplement),
                            colour: colour,
                            group: p.group.name
						});

						// }

					}
				})

			} else {
                axios.get('/api/table/users/count').then((response) => {
                    if(response.data.count > 0){
                        this.dataToLoad = true;
                    }
                });
				// console.log("RUNNING DIS");

				axios.get('/api/table/users').then((response) => {
					this.participants = response.data;

					for (let i = 0; i < this.participants.length; i++) {
						let p = this.participants[i];
						let totalRequired = 0;
						if (p.group != undefined) totalRequired = p.group.fee;

						let colour = "normal";

						// TODO WHAT DECIDES THE HIGHLIGHTING
						// decide whether they should highlight in red or yellow or nothing at all
						if(!p.supplement){
                            p.supplement = 0;
                        }
						if ((p.total_due_today - (p.total_paid - p.supplement)) == 100) {
							colour = 'yellow';
						} else if ((p.total_due_today - (p.total_paid - p.supplement)) >= 200) {
							colour = 'red';
						}
                        
                        // for(let i = 0; i < 10000; i++){
                        this.rows.push({
                            id: p.id,
                            first_name: p.first_name,
                            last_name: p.last_name,
                            email: p.email,
                            package_cost: parseInt(p.total_required),
                            total_paid: parseInt(p.total_paid),
                            total_due_today: p.total_due_today,
                            total_overdue: (p.total_due_today - p.total_paid),
                            total_remaining: (p.total_required - p.total_paid),
                            supplement: (p.supplement),
                            colour: colour,
                            group:p.group.name
                        });


					}



				})
			}
		},
		create() {
			// console.log("trying to create");
			this.creating = true;
		},

		updateSearch(items) {
			this.filteredGroupItems = items;
		},
		update(participant) {
			// console.log("update");

			// console.log(participant.id);
			let updated = false;
			for (var i = 0; i < this.participants.length; i++) {
				if (this.participants[i].id == participant.id) {
					// console.log(this.participants[i]);
					this.participants[i].first_name = participant.first_name;
					this.participants[i].last_name = participant.last_name;
					this.participants[i].email = participant.email;
				}
			}

		},
		onGroupSelect(selected) {
			this.participant.group = selected.id;
		},

	},
    watch:{
        rows(){
            if(this.rows.length == this.participants.length){
                this.loaded = true;
                this.$toasted.success("Loaded participants.").goAway(6000);
            }
        }
    },
	components: {
		Participant,
		TransferFunds,
		Search,
		NSearch,
		VueGoodTable,
		// Datatable,
	},
}
</script>
